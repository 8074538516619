import { LOCATION_INITIALIZED } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Injector, provideZoneChangeDetection } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from "@azure/msal-angular";
import {
  ILoggerCallback as IMsalLoggerCallback,
  IPublicClientApplication as IMsalPublicClientApplication,
  BrowserCacheLocation as MsalBrowserCacheLocation,
  InteractionType as MsalInteractionType,
  LogLevel as MsalLogLevel,
  PublicClientApplication as MsalPublicClientApplication,
} from "@azure/msal-browser";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IxModule, ToastService } from "@siemens/ix-angular";
import { pendingRequestsInterceptor$ as pendingRequestsInterceptor } from "ng-http-loader";
import { ModalModule } from "ngx-bootstrap/modal";
import { catchError, EMPTY, from, mergeMap, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { provideApiBaseUrl } from "./api/provide-api-base-url";
import { provideNSwagClient } from "./api/provide-n-swag-client";
import { appRoutes } from "./app.routes";
import { provideAppErrorHandler } from "./core/error/app-error-handler";
import { httpErrorToastInterceptor } from "./core/interceptors/http-error-toast.interceptor";
import { AppThemeService } from "./core/services/app-theme.service";

const msalLoggerCallback: IMsalLoggerCallback = (logLevel: MsalLogLevel, message: string) => {
  if (logLevel === MsalLogLevel.Error) {
    console.error(message);
  } else if (logLevel === MsalLogLevel.Warning) {
    console.warn(message);
  } else {
    console.log(message);
  }
};

const msalInstanceFactory = (): IMsalPublicClientApplication =>
  new MsalPublicClientApplication({
    auth: {
      clientId: environment.auth.clientId,
      authority: environment.auth.authority,
      redirectUri: "/",
      postLogoutRedirectUri: null,
    },
    cache: {
      cacheLocation: MsalBrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: msalLoggerCallback,
        logLevel: MsalLogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });

const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => ({
  interactionType: MsalInteractionType.Redirect,
  protectedResourceMap: new Map([
    [environment.apiBaseUrl, environment.auth.scopes.trim().split(/\s+/)],
    ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
  ]),
});

const msalGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: MsalInteractionType.Redirect,
  authRequest: {
    scopes: environment.auth.scopes.trim().split(/\s+/),
  },
  loginFailedRoute: undefined,
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideAnimations(),
    ...AppThemeService.getProviderList(),
    /* withInterceptorsFromDi required for MsalInterceptor, withFetch also on Msal's Documentation */
    provideHttpClient(withInterceptors([pendingRequestsInterceptor, httpErrorToastInterceptor]), withInterceptorsFromDi(), withFetch()),
    importProvidersFrom([
      ModalModule.forRoot(),
      IxModule.forRoot(),
      TranslateModule.forRoot({
        useDefaultLang: true,
        defaultLanguage: "en",
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http, "assets/i18n/", ".json"),
          deps: [HttpClient],
        },
      }),
    ]),
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector, translate: TranslateService) => (): Observable<unknown> =>
        from(injector.get(LOCATION_INITIALIZED, Promise.resolve())).pipe(
          mergeMap(() => {
            translate.addLangs(["en", "de"]);
            return translate.use(translate.defaultLang);
          }),
          catchError(() => EMPTY)
        ),
      deps: [Injector, TranslateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (toast: ToastService) => (): Promise<void> => {
        toast.setPosition("top-right");
        return Promise.resolve();
      },
      deps: [ToastService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideNSwagClient(),
    provideApiBaseUrl(environment.apiBaseUrl), // For NSwag's Client class
    provideAppErrorHandler(),
  ],
};
