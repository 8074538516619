import { ClassProvider, ErrorHandler, Injectable, Injector } from "@angular/core";
import { ToastService } from "@siemens/ix-angular";

export const provideAppErrorHandler = (): ClassProvider => ({ provide: ErrorHandler, useClass: AppErrorHandler });

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(protected injector: Injector) {
    super();
  }

  public override handleError(err: unknown): void {
    try {
      const toast = this.injector.get(ToastService, null);
      toast?.show({
        // Unfortunately not all error objects are instances of Error (ie. GeolocationPositionError)
        message: `${err && typeof err === "object" && "message" in err && typeof err.message === "string" ? err.message : err}`,
        autoCloseDelay: 8000,
        type: "error",
      });
    } catch (innerError: unknown) {
      super.handleError(innerError);
    }
    super.handleError(err);
  }
}
