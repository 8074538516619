import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject, Injector } from "@angular/core";
import { ToastService } from "@siemens/ix-angular";
import { catchError, Observable, throwError } from "rxjs";

/** Display the http error message on a Toast */
export const httpErrorToastInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const injector = inject(Injector);
  return next(request).pipe(
    catchError((err: unknown) => {
      // Don't display Toast
      if (err instanceof HttpErrorResponse && err.status === 404 && ["/FacilityFilter", "/IBaseFilter"].some((u) => request.url.includes(u))) {
        return throwError(() => err);
      }

      const toast = injector.get(ToastService, null);
      toast?.show({ message: `${err instanceof HttpErrorResponse ? err.message : err}`, autoCloseDelay: 8000, type: "error" });
      return throwError(() => err);
    })
  );
};
